import { title } from '@/utilities/filters'
import { formatCurrency } from '@/utilities/functions'
import cloneDeep from 'lodash/cloneDeep'

export const defaultHeaders = (context: Record<string, any>): Record<string, any> => {
  const jobTypes = {
    text: title(String(context.$t('cmac.jobTypes'))),
    value: 'jobClassificationText',
  }
  const type = {
    text: title(String(context.$t('common.type'))),
    value: 'type',
  }
  return {
    expanded: {
      vueComponent: { name: 'c-table-action-icon' },
      text: '',
      value: 'expanded',
      width: '1%',
    },
    holidayRates: {
      jobTypes: cloneDeep(jobTypes),
      holidays: {
        text: title(String(context.$t('cmac.holidays'))),
        value: 'holidays',
      },
      type: cloneDeep(type),
    },
    hotelMarkupRates: {
      jobTypes: cloneDeep(jobTypes),
      type: cloneDeep(type),
    },
    vehicleRates: {
      applies: {
        text: title(String(context.$t('cmac.applies'))),
        value: 'applies',
      },
      jobTypes: cloneDeep(jobTypes),
      journeyChargeMethod: {
        text: title(String(context.$t('cmac.journeyChargeMethod'))),
        value: 'journeyChargeMethodText',
      },
      regions: {
        text: title(String(context.$t('cmac.regions'))),
        value: 'regions',
      },
      standDownChargeMethod: {
        text: title(String(context.$t('cmac.standDownChargeMethod'))),
        value: 'standDownChargeMethodText',
      },
      type: cloneDeep(type),
      vehicles: {
        text: title(String(context.$t('cmac.vehicles'))),
        value: 'vehicleText',
      },
      waitingTime: {
        text: title(String(context.$t('cmac.waitingTime'))),
        value: 'waitingTime',
      },
      waitingTimeFree: {
        text: title(String(context.$t('cmac.waitingTimeFree'))),
        value: 'waitingTimeFree',
      },
      waitingTimeRate: {
        renderFunction: (v: string) => formatCurrency(v),
        text: title(String(context.$t('cmac.waitingTimeRate'))),
        value: 'waitingTimeRate',
      },
    },
  }
}

export const headers = (context: Record<string, any>): Record<string, any> => {
  return {
    holidayRatesHeaders: holidayRatesHeaders(context),
    hotelMarkupRatesHeaders: hotelMarkupRatesHeaders(context),
    vehicleRatesHeaders: vehicleRatesHeaders(context),
  }
}

export const holidayRatesHeaders = (context: Record<string, any>): Record<string, any>[] => {
  const { expanded, holidayRates } = defaultHeaders(context)
  const { jobTypes, holidays, rate } = holidayRates
  return [jobTypes, holidays, rate, expanded]
}

export const hotelMarkupRatesHeaders = (context: Record<string, any>): Record<string, any>[] => {
  const { expanded, hotelMarkupRates } = defaultHeaders(context)
  const { jobTypes } = hotelMarkupRates
  return [jobTypes, expanded]
}

export const vehicleRatesHeaders = (context: Record<string, any>): Record<string, any>[] => {
  const { expanded, vehicleRates } = defaultHeaders(context)
  const {
    applies,
    jobTypes,
    journeyChargeMethod,
    regions,
    standDownChargeMethod,
    vehicles,
    waitingTime,
    waitingTimeFree,
    waitingTimeRate,
  } = vehicleRates
  return [
    vehicles,
    jobTypes,
    regions,
    journeyChargeMethod,
    standDownChargeMethod,
    applies,
    waitingTime,
    waitingTimeFree,
    waitingTimeRate,
    expanded,
  ]
}
