import cloneDeep from 'lodash/cloneDeep'

export const holidayRatesItems = (
  context: Record<string, any>,
  rateCards: Record<string, any>[]
): Record<string, any>[] => {
  return context
    ? cloneDeep(rateCards)
        .filter(c => c.rateCardEntryType !== 'Bill')
        .map(c => ({
          ...c,
          expanded: context.rateCardIsExpanded(c),
          holidays: c.holidayType,
          jobTypes: c.jobClassification,
          type: c.rateCardEntryType,
        }))
    : []
}

export const hotelRateCardItems = (
  context: Record<string, any>,
  rateCards: Record<string, any>[]
): Record<string, any>[] => {
  return context
    ? cloneDeep(rateCards)
        .filter(c => c.rateCardEntryType !== 'Bill')
        .map(c => ({
          ...c,
          expanded: context.rateCardIsExpanded(c),
          itemSpecificConfig: {
            expanded: { vueComponent: { props: { color: 'primary', text: 'expand_more' } } },
          },
        }))
    : []
}

export const items = (context: Record<string, any>): Record<string, any> => {
  const rateCards = context.$store.getters['suppliers/rateCards'] || {}
  const { holidayRateCardEntries, hotelRateCards, vehicleRateCardEntries } = rateCards
  return {
    holidayRatesItems: holidayRatesItems(context, holidayRateCardEntries),
    hotelRateCardItems: hotelRateCardItems(context, hotelRateCards),
    vehicleRatesItems: vehicleRatesItems(context, vehicleRateCardEntries),
  }
}

export const vehicleRatesItems = (
  context: Record<string, any>,
  rateCards: Record<string, any>[]
): Record<string, any>[] => {
  return context
    ? cloneDeep(rateCards)
        .filter(c => c.rateCardEntryType !== 'Bill')
        .map(c => {
          const expanded = context.rateCardIsExpanded(c)
          return {
            ...c,
            expanded,
            itemSpecificConfig: {
              expanded: {
                vueComponent: {
                  props: {
                    color: expanded ? 'primary' : 'grey',
                    text: expanded ? 'expand_less' : 'expand_more',
                  },
                },
              },
            },
            jobTypes: c.jobClassification,
            regions: c.regions.map((x: any) => x.name).join(),
            type: c.rateCardEntryType,
            vehicles: c.vehicle,
            waitingTime: c.waitingTimeMinutes,
            applies: describeVehicleRateCard(c),
          }
        })
    : []
}

function describeVehicleRateCard(rateCardEntry: Record<string, any>) {
  console.log(rateCardEntry)
  const fromTime = rateCardEntry.fromTime.substring(0, 5)
  const toTime = rateCardEntry.toTime.substring(0, 5)
  if (rateCardEntry.appliesOnDays === 127) {
    return 'Every day from ' + fromTime + ' to ' + toTime
  }
  return rateCardEntry.appliesOnDaysDescription + ' from ' + fromTime + ' to ' + toTime
}
