
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class WaitingTimeRateDisplay extends Vue {
  @Prop({ type: Object })
  item!: any
  @Prop({ type: String })
  currency!: string

  get hasWaitingTimeRate() {
    return this.item.ratePerUnit > 0
  }
}
