
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class HolidayUpliftDisplay extends Vue {
  @Prop({ type: Array })
  items!: Array<any>

  @Prop({ type: String })
  currency!: string

  headers: Array<any> = [
    {
      text: this.$t('common.from'),
      value: 'fromDateUtc',
    },
    {
      text: this.$t('common.to'),
      value: 'toDateUtc',
    },
    {
      text: this.$t('cmac.markup'),
      value: 'markupPercentage',
    },
    {
      text: this.$t('cmac.addedPrice'),
      value: 'additionalPrice',
    },
  ]

  get computedItems() {
    return this.items.sort((a, b) => (a.fromDateUtc > b.fromDateUtc ? 1 : -1))
  }
}
