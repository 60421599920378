
import { Component, Vue, Watch } from 'vue-property-decorator'
import { headers, items } from '@/pages/rateCards/rateCardHelpers'
import { disableSorting } from '@/utilities/org'
import api from '@/utilities/api'
import VehicleRateTable from './components/VehicleRateTable'
import WaitingTimeRateDisplay from './components/WaitingTimeRateDisplay'
import StandDownRateDisplay from './components/StandDownRateDisplay'
import HolidayRateDisplay from './components/HolidayRateDisplay'
import HolidayUpliftDisplay from './components/HolidayUpliftDisplay'

@Component({
  components: {
    VehicleRateTable,
    WaitingTimeRateDisplay,
    StandDownRateDisplay,
    HolidayRateDisplay,
    HolidayUpliftDisplay,
  },
})
export default class RateCardsPage extends Vue {
  // data // ***
  loading = false
  expanded: Record<string, any>[] = []
  showExpand = false
  singleExpand = false
  rateData: any = null
  customDatesData: Record<string, any>[] = []

  // computed // ***
  get headers() {
    return headers(this)
  }
  get holidayRatesHeaders() {
    return disableSorting(this.headers.holidayRatesHeaders)
  }
  get items() {
    return items(this)
  }
  get supplier() {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get supplierId() {
    return this.supplier?.id
  }
  get url() {
    return `rateCard/${this.supplierId}/all`
  }
  get vehicleRatesHeaders() {
    return disableSorting(this.headers.vehicleRatesHeaders)
  }
  get vehicleRateEntries() {
    if (this.rateData === null) return []
    return this.rateData.sort((a: any, b: any) => (a.checkOrder < b.checkOrder ? 1 : -1))
  }
  get customDatesCurrency() {
    return this.vehicleRateEntries[0]?.currency ?? 'GBP'
  }

  // watch // ***
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.getRateCard()
  }

  // created // ***
  created() {
    if (this.supplierId) {
      this.getRateCard()
    }
  }

  // methods // ***
  currency(entry: any) {
    if (entry === null) return 'GBP'
    return entry.currency
  }
  appliesOnFullDescription(entry: any) {
    return (
      entry.appliesOnDaysDescription +
      ' - ' +
      entry.fromTime.substring(0, 5) +
      ' ' +
      this.$t('common.to') +
      ' ' +
      entry.toTime.substring(0, 5)
    )
  }
  vehicleRatePricingTiers(entry: any) {
    if (entry === null) return []
    return entry.pricingTiers
  }
  holidayRateItems(entry: any) {
    if (entry === null) return []
    return entry.customDates
  }
  standDownRateItems(entry: any) {
    if (entry === null) return []
    return entry.standDownTiers
  }
  waitingTime(entry: any) {
    if (entry === null) return {}
    return entry.waitingTime
  }
  getRateCard() {
    this.loading = true
    //This gets the current cost rate card entries for the supplier
    api(`ratecardtemplate/${this.supplierId}/currentcostentries`, {}, {})
      .then(response => {
        this.rateData = response.data
      })
      .finally(() => {
        this.loading = false
        this.getCustomDates()
      })
  }
  getCustomDates() {
    this.loading = true
    api(`ratecardtemplate/${this.supplierId}/currentcostcustomdates`, {}, {})
      .then(response => {
        this.customDatesData = response.data
      })
      .finally(() => {
        this.loading = false
      })
  }
}
