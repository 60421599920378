
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class VehicleRateTable extends Vue {
  @Prop({ type: Array })
  items!: Array<any>

  @Prop({ type: String })
  currency!: string

  headers: Array<any> = [
    {
      text: 'Type',
      value: 'tierType',
    },
    {
      text: 'From',
      value: 'from',
    },
    {
      text: 'To',
      value: 'to',
    },
    {
      text: 'Price',
      value: 'price',
    },
  ]

  get computedItems() {
    return this.items.sort((a, b) => (a.from > b.from ? 1 : -1))
  }

  showFrom(item: any): boolean {
    return item && item.from > 0
  }
  getTierType(item: any): string {
    if (!item) return ''
    switch (item.tierType) {
      case 0:
        return 'Hourly'
      case 1:
        return 'Mileage'
      case 2:
        return 'Minimum Price'
      case 3:
        return 'Mileage Fixed'
      case 5:
        return 'Kilometres'
      case 6:
        return 'Kilometres Fixed'
      default:
        return ''
    }
  }
}
