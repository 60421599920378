
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class CoveragePanel extends Vue {
  // props // ***
  @Prop({ type: Array })
  coverageAreas!: Record<string, any>[]
  @Prop({ type: Boolean })
  dense!: boolean
  @Prop()
  activeArea!: number | string | null

  // data // ***
  selectedItem: number | null = null

  // watch // ***
  @Watch('activeArea')
  onActiveAreaChanged() {
    const { activeArea, coverageAreas } = this
    const index = coverageAreas.findIndex((c: Record<string, any>) => c.id === activeArea)
    this.selectedItem = index === -1 ? null : index
  }

  // methods // ***
  onAreaSelected(e: number) {
    this.$emit('area-selected', e)
  }
}
