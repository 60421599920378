
import { Component, Vue, Watch } from 'vue-property-decorator'
import CoveragePanel from '@/pages/coverage/CoveragePanel'

@Component({ components: { 'c-coverage-panel': CoveragePanel } })
export default class CoveragePage extends Vue {
  // data // ***
  settingsMenuActive = false
  activeArea: number | null = null

  // computed // ***
  get coverageAreas() {
    return this.$store.getters['suppliers/coverageAreas']
  }
  get mdAndUp() {
    return this.$vuetify.breakpoint.mdAndUp
  }
  get showCoveragePanel() {
    return this.mdAndUp
  }
  get showSettingsMenu() {
    return !this.mdAndUp
  }
  get supplier() {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get supplierId() {
    return this.supplier?.id
  }
  get url(): string {
    return 'supplieradmin/getorganisationareas/' + this.supplierId
  }

  // created // ***
  created() {
    if (this.supplierId) this.fetchCoverageAreas()
  }

  // watch // ***
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetchCoverageAreas()
  }

  // methods // ***
  fetchCoverageAreas() {
    this.activeArea = null
    this.$store.dispatch('suppliers/fetchCoverageAreas', { url: this.url })
  }
  onAreaSelected(e: number) {
    this.activeArea = e
  }
  onLayerClicked(e: Record<string, any>) {
    this.activeArea = e?.id
  }
}
